@use "../../../../../styles/mixins";

.embedResponsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;


  .embedResponsiveItem,
  embed,
  iframe,
  object,
  img,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    box-shadow: 10px 5px 10px rgba(0,0,0,.2);
    object-fit: scale-down;
  }
}

.embedResponsive16by9 {
  padding-bottom: 56.25%;
}
.embedResponsive4by3 {
  padding-bottom: 75%;
}
